import { Pipe, PipeTransform } from '@angular/core';
import { FieldNurse } from 'src/app/shared/models/field-nurse';
import { FieldNurseService } from 'src/app/shared/services/field-nurse.service';

@Pipe({
  name: 'username',
  pure: true,
})
export class UserNamePipe implements PipeTransform {
  constructor(private fieldNurseProvider: FieldNurseService) {}
  async transform(item: string) {
    if (!item) {
      return;
    }
    const fieldNurse: FieldNurse = await this.fieldNurseProvider.find(item);
    if (fieldNurse) {
      return `${fieldNurse.firstName} ${fieldNurse.lastName}`;
    }
    return 'Unbekannter Benutzer';
  }
}
