import {
  IInsuranceFixedPrice,
  IService,
  IServiceQuantityPerUnit,
  ServiceLocation,
  ServiceType,
} from '@alberta/konexi-shared';
import { ignore } from '@common/tracking/tracker';
import { BaseViewModel } from '@common/viewmodel/base-view-model';

export class ServiceViewModel extends BaseViewModel implements IService {
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  name: string;
  description: string;
  additionalDescription: string;
  customerNumber: string;
  duration: number;
  therapyIds: number[] = [];
  serviceType: ServiceType;
  insuranceFixedPrice: IInsuranceFixedPrice[] = [];
  billingUnits: IServiceQuantityPerUnit[];
  location: ServiceLocation;
  @ignore()
  checked: boolean;
}
