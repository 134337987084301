import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'itl-loading-modal',
  templateUrl: 'loading-modal-page.html',
  styleUrls: ['loading-modal-page.scss'],
})
export class LoadingModalComponent {
  @Input() header: string;
  @Input() message: Observable<string>;

  constructor(private _modalController: ModalController) {}

  public async close() {
    await this._modalController
      .dismiss({
        stopped: true,
      })
      .catch(error => console.log(error));
  }
}
