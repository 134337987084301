import { IModel } from '@alberta/konexi-shared';
import moment from 'moment';
import { from, Observable } from 'rxjs';
import { EntityCreateCommand } from 'src/app/commands/entity-create-command';
import { IQuerySearchOptions } from 'src/app/shared/services/contracts/query/query-search-options';

import { EntityDeleteCommand } from '../../commands/entity-delete-command';
import { EntityUpdateCommand } from '../../commands/entity-update-command';
import { StateExtensionAction } from '../contracts/state/state-extension-action';
import { dateFormat, dateFormatWithoutTime } from '../date/format';
import { Changes, getChanges, MomentInput } from '../tracking';
import { removeTimeMetadataKey } from '../tracking/tracking-keys';
import { BaseViewModel } from '../viewmodel/base-view-model';
import { ComponentModelDependencies } from './component-model-dependencies';
import { ComponentModelInfo, IModelInfo } from './component-model-info';

export abstract class BaseComponentModel<T extends IModel> {
  public isStateHydrated = this.componentModelDepencies.stateRegistry.isHydrated;
  private _modelInfo: IModelInfo<T>;

  constructor(
    private _modelName: string,
    protected componentModelDepencies: ComponentModelDependencies,
    private _componentModelInfo: ComponentModelInfo
  ) {
    this._modelInfo = this._componentModelInfo.getInfo(this._modelName);
    this.componentModelDepencies.stateRegistry.register(
      this._modelName,
      this._modelInfo.state,
      this._modelInfo.extensions
    );
  }

  public getAll(): Observable<T[]> {
    return from(this.componentModelDepencies.queryService.getAll<T>(this._modelInfo.database));
  }

  public get(id: string): Observable<T> {
    return from(this.componentModelDepencies.queryService.get<T>(id, this._modelInfo.database));
  }

  public search(query: string, options?: IQuerySearchOptions): Observable<T[]> {
    return from(this.componentModelDepencies.queryService.search<T>({ query }, this._modelInfo.database, options));
  }

  public select<TValue>(path: string = 'items', modelName: string = this._modelName): Observable<TValue> {
    return this.componentModelDepencies.stateRegistry.select<TValue, T>(modelName, path);
  }

  public registerItems(viewModels: IModel[], modelName: string = this._modelName) {
    this.componentModelDepencies.stateRegistry.update(modelName, 'items', viewModels);
  }

  public registerSelected(viewModel: IModel, modelName: string = this._modelName) {
    this.componentModelDepencies.stateRegistry.update(modelName, 'selectedItem', viewModel);
  }

  public register(value: any, path: string, modelName: string = this._modelName): void {
    this.componentModelDepencies.stateRegistry.update(modelName, path, value);
    // tslint:disable-next-line: no-floating-promises
    this.componentModelDepencies.stateRegistry.runExtension(
      modelName,
      StateExtensionAction.create,
      Array.isArray(value) ? value : [value]
    );
  }

  public async create(item: T, additionalSyncInfo?: Record<string, any>): Promise<T | void> {
    this.removeTime(item);
    if (additionalSyncInfo?.ignoreQueue) {
      const result = await this.executeCreateCommand(item, additionalSyncInfo);
      await this.executeCreateState(item);

      return result;
    }

    await this.executeCreateState(item);

    await this.executeCreateCommand(item, additionalSyncInfo);
  }

  public async edit(viewModel: T, item: T, additionalSyncInfo?: Record<string, any>): Promise<T | void> {
    this.removeTime(item);
    const changes = getChanges(viewModel as any as BaseViewModel, item);
    if (!changes.changes.length) {
      return;
    }

    if (additionalSyncInfo?.ignoreQueue) {
      const result = await this.executeUpdateCommand(item, changes, additionalSyncInfo);
      await this.executeUpdateState(item, changes);

      return result;
    }

    await this.executeUpdateState(item, changes);

    await this.executeUpdateCommand(item, changes, additionalSyncInfo);
  }

  public async delete(item: T) {
    await this.componentModelDepencies.commandBroker.handle(
      new EntityDeleteCommand(item, this._modelInfo.channel.DELETE, this._modelInfo.dto)
    );

    await this.componentModelDepencies.stateRegistry.removeBySync(this._modelName, 'items', [item]);
  }

  private async executeCreateState(item: T) {
    // Edge Case and very dirty
    // Until State is refactored we need to write entities created by ourselfs into state
    // so the frontend can refresh after leaving create dialogue
    // We rely on the item to have a createdBy field
    try {
      const accountId = this.componentModelDepencies.authService.authentication.account._id;
      if ((item as any)?.createdBy === accountId || (item as any)?.fieldNurseId === accountId) {
        await this.componentModelDepencies.stateRegistry.writeToStateWithoutPersister(this._modelName, 'items', item);
      }
    } catch (error) {
      window.logger.error('error during executeCreateState', error);
    }
    await this.componentModelDepencies.stateRegistry.runExtension(this._modelName, StateExtensionAction.create, [item]);
  }

  private async executeUpdateState(item: T, changes: Changes) {
    await this.componentModelDepencies.stateRegistry.updateBySync(this._modelName, 'items', [item]);

    await this.componentModelDepencies.stateRegistry.runExtension(
      this._modelName,
      StateExtensionAction.update,
      [item],
      { changes }
    );
  }

  private async executeCreateCommand(item: T, additionalSyncInfo?: Record<string, any>) {
    return this.componentModelDepencies.commandBroker.handle(
      new EntityCreateCommand(item, this._modelInfo.channel.CREATE, this._modelInfo.dto, additionalSyncInfo)
    );
  }

  private async executeUpdateCommand(item: T, changes: Changes, additionalSyncInfo?: Record<string, any>) {
    return this.componentModelDepencies.commandBroker.handle(
      new EntityUpdateCommand(changes, item, this._modelInfo.channel.EDIT, this._modelInfo.dto, additionalSyncInfo)
    );
  }

  private removeTime(item: T) {
    for (const property in item) {
      if (!item.hasOwnProperty(property)) {
        continue;
      }

      if (
        item[property] !== null &&
        typeof item[property] === 'object' &&
        !moment(item[property] as any as MomentInput, dateFormat, true).isValid() &&
        !moment(item[property] as any as MomentInput, dateFormatWithoutTime, true).isValid()
      ) {
        if (Array.isArray(item[property])) {
          for (const arrayItem of (item as any)[property]) {
            if (arrayItem !== null && typeof arrayItem === 'object') {
              this.removeTime(arrayItem);
            }
          }
        } else if (!Array.isArray(item[property])) {
          this.removeTime((item as any)[property]);
        }
      } else if (
        moment(item[property] as any as MomentInput, dateFormat, true).isValid() ||
        moment(item[property] as any as MomentInput, dateFormatWithoutTime, true).isValid()
      ) {
        const metadata = Reflect.getMetadata(removeTimeMetadataKey, item, property);
        if (!metadata) {
          continue;
        }
        (item as any)[property] = moment(item[property] as any).format('YYYY-MM-DD');
        Reflect.deleteMetadata(removeTimeMetadataKey, item, property);
      }
    }
  }
}
