import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SelectionItem } from 'src/app/shared/models/selectionItem';

import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-checkbox-select',
  templateUrl: './checkbox-select.component.html',
})
export class CheckboxSelectComponent implements IBaseInputContract, ControlValueAccessor {
  @Input() public title: string;
  @Input() public headerText: string;
  @Input() public selectionItems: SelectionItem[];
  @Input() public multipleSelectedText: string;
  @Input() public compareFn: any;
  @Input() public placeholder = '';
  @Input() public required = false;
  @Input() public icon: string;
  @Input() public disabled: boolean;
  @Input() public description: string;
  @Output() public change: EventEmitter<[]> = new EventEmitter<[]>();

  public value: any;
  private onChange = (text: string) => {};
  private onTouched = () => {};

  constructor(
    private _alertCtrl: AlertController,
    private _translate: TranslateService,
    @Optional() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public async showCheckbox(): Promise<void> {
    // create options
    const checkboxoptions = [];
    this.selectionItems.forEach(value => {
      checkboxoptions.push({
        type: 'checkbox',
        label: value.value,
        value: value.id,
        checked: this.checkifChecked(value.id),
      });
    });
    // create Alert
    const alert = await this._alertCtrl.create({
      backdropDismiss: false,
      header: this.headerText,
      inputs: checkboxoptions,
      cssClass: 'overridePrimaryToVariant',
      buttons: [
        {
          text: this._translate.instant('CANCEL_BUTTON'),
        },
        {
          text: this._translate.instant('DONE_BUTTON'),
          handler: data => {
            if (!this.value) {
              this.value = [];
            }
            this.value = data;
            this.updateChanges();
            this.change.emit(data);
          },
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
    this.onTouched();
  }

  private checkifChecked(id: any): boolean {
    return (
      this.value &&
      this.value.find(item => {
        if (this.compareFn) {
          return this.compareFn(id, item);
        }
        return id === item;
      }) !== undefined
    );
  }

  public updateChanges() {
    this.onChange(this.value);
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.updateChanges();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
