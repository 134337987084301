<itl-text-input-template
  [control]="ngControl"
  [icon]="icon"
  [title]="title"
  [value]="value"
  [maxlength]="maxlength"
  [description]="description"
  [required]="required"
  (focusClicked)="setFocus()"
>
  <div class="input-delete-wrapper">
    <textarea
      #textInput
      autosize
      [minRows]="1"
      class="alb-input"
      [(ngModel)]="value"
      [maxlength]="maxlength"
      [placeholder]="placeholder"
      [ngClass]="{ bold: value && value.length }"
      (focus)="markAsTouched()"
      (ngModelChange)="updateChanges()"
      [style.resize]="'none'"
      (keydown.enter)="prevent($event)"
      (keyup.enter)="prevent($event)"
    ></textarea>
    <div class="deleteButton" (click)="removeSelected($event)" *ngIf="value">
      <ion-icon name="alberta-delete"></ion-icon>
    </div>
  </div>
</itl-text-input-template>
