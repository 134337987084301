import { IAppointment } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from '@common/state/state-extension';
import { AppointmentModelName } from '../shared/models/model-names';

export const ALBERTA_CALENDAR_STATE_NAME = 'AlbertaCalendarAppointment';
@Injectable({ providedIn: 'root' })
export class AlbertaCalendarAppointmentStateExtension extends BaseStateExtension<IAppointment> {
  name: string;

  private _dateRangeToObserve: { from: Date; to: Date } | null = null;

  public observeRange(dateRangeToObserve: { from: Date; to: Date } | null) {
    this._dateRangeToObserve = dateRangeToObserve;
  }

  public async canBeStored(item: IAppointment): Promise<boolean> {
    if (this._dateRangeToObserve === null) {
      return false;
    }
    return (
      !!item.calendarResourceId &&
      item.archived == false &&
      item.endDate <= this._dateRangeToObserve.to &&
      item.startDate >= this._dateRangeToObserve.from
    );
  }
  protected async afterCreateInternal(item: IAppointment) {
    await this.stateRegistry.createBySync(AppointmentModelName, ALBERTA_CALENDAR_STATE_NAME, item);
  }
  protected async afterUpdateInternal(item: IAppointment) {
    await this.stateRegistry.updateBySync(AppointmentModelName, ALBERTA_CALENDAR_STATE_NAME, [item]);
  }
  protected async removeFromState(item: IAppointment) {
    await this.stateRegistry.removeBySync(AppointmentModelName, ALBERTA_CALENDAR_STATE_NAME, [item]);
  }
}
