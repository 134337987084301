import { CareProposalStatus, InstitutionType, NoteType } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationToken, IAuthenticationAccount } from '@common/contracts/authentication/authentication-account';
import amplitude from 'amplitude-js';
import makeDebug from 'debug';
import { cloneDeep } from 'lodash';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { MessagePayload } from 'src/app/shared/models/share/message-payload';
import { environment } from 'src/environments/environment';

import { ChatConnectionStateService } from './chat/chat-connection-state.service';
import { ChatChannelDetail } from './chat/model/chat.model';
import { ConnectionStateService } from './connection-state.service';
import { IConnectionStateService } from './contracts/sync/connection-state-service';

const debug = makeDebug('services:tracker');

/**
 * Action event state for every cancelable user interaction
 */
export enum ActionEvent {
  Start = 'Start',
  Done = 'Done',
  Cancel = 'Cancel',
}

/**
 * Share event type for every share action that somehow transfers an object
 * from the user to somewhere else
 */
export enum ShareEventType {
  Chat = 'Chat',
  Fax = 'Fax',
  Email = 'Email',
  Download = 'Download',
  Print = 'Print',
  NativeShare = 'NativeShare',
}

export enum ActivityType {
  Unknown,
  Patient,
  PatientList,
  Institution,
}

export class ScheduledLogEvent {
  private timeoutHandler: NodeJS.Timeout;

  constructor(timeoutHandler: NodeJS.Timeout) {
    this.timeoutHandler = timeoutHandler;
  }

  public cancel() {
    global.clearTimeout(this.timeoutHandler);
  }
}

@Injectable({ providedIn: 'root' })
export class TrackerService {
  private isTrackingEnabled = true;
  private _isAuthOnline = false;
  private _isAuthenticated = false;
  tracker: amplitude.AmplitudeClient;
  private _patientApptracker: amplitude.AmplitudeClient;
  constructor(
    private readonly _chatConnectionStateService: ChatConnectionStateService,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount
  ) {
    this.tracker = amplitude.getInstance();
    this.tracker.init(environment.amplitude.apiKey);

    if (environment.amplitude.patientApp?.enabled) {
      this._patientApptracker = amplitude.getInstance('patientApp');
      this._patientApptracker.init(environment.amplitude.patientApp.apiKey);
    }
  }

  public set isAuthOnline(isOnline: boolean) {
    debug('set auth online status', isOnline);
    this._isAuthOnline = isOnline;
  }

  public set isAuthenticated(isAuthenticated: boolean) {
    debug('set authentication status', isAuthenticated);
    this._isAuthenticated = isAuthenticated;
  }

  public isDebugUser() {
    try {
      return (this._authenticationAccount?.account as any)?.debug === true;
    } catch (e) {
      window.logger.error('[TrackerService][isDebugUser] Failed to check for debug flag', e);
      return false;
    }
  }

  public identify(userId: string, email: string, organization: any) {
    debug('identify user', { userId, email, organization });
    this.tracker.setUserId(userId);
    this.tracker.setUserProperties({
      email,
    });
    if (organization) {
      this.tracker.setUserProperties({
        tenantId: organization.tenantId,
        organizationName: organization.name,
        organizationId: organization._id,
        withSync: this._platformSync.canBeSynced,
        modules: organization.modules,
      });
    }

    if (this._patientApptracker) {
      this._patientApptracker.setUserId(userId);
      this._patientApptracker.setUserProperties({
        email,
      });

      if (organization) {
        this._patientApptracker.setUserProperties({
          tenantId: organization.tenantId,
        });
      }
    }
  }

  public setVersion(release: string, version: string) {
    debug('set release and version', release, version);
    this.tracker.setVersionName(release + '#' + version);
  }

  public clearIdentify() {
    debug('clear identify');
    this.tracker.clearUserProperties();
  }

  public setTrackingEnabled(enabled: boolean) {
    debug('set tracking status', enabled);
    this.isTrackingEnabled = enabled;
  }

  private _logPatientAppEvent(name: string, params: any = null) {
    if (!this._patientApptracker) {
      return;
    }

    global.setTimeout(() => {
      try {
        debug('log patientApp event', name, params);
        this._patientApptracker.logEvent(name, params);
      } catch (err) {
        window.logger.error('patientApp tracking error', err);
      }
    });
  }

  /**
   * Default log event for Amplitude Service
   */
  private logEvent(name: string, params: any = null) {
    try {
      if (!this.isTrackingEnabled) {
        return;
      }

      let populatedParams = cloneDeep(params);
      if (!populatedParams) {
        populatedParams = {};
      }
      populatedParams.isAuthOnline = this._isAuthOnline;
      populatedParams.isAuthenticated = this._isAuthenticated;
      populatedParams.isAgentChatOnline = this._chatConnectionStateService.isAgentChatOnline;
      populatedParams.isConnected = this._connectionStateService.isConnected;
      populatedParams.withSync = this._platformSync.canBeSynced;

      global.setTimeout(() => {
        try {
          debug('log event', name, populatedParams);
          this.tracker.logEvent(name, populatedParams);
        } catch (err) {
          window.logger.error('user tracking error', err);
        }
      });
    } catch (err) {
      window.logger.error(`[TrackerService][logEvent] Failed to log event! name: ${name}`, err);
    }
  }

  /**
   * Schedule a log event for Amplitude Service. This log event is scheduled to log after
   * a certain delay, if no canceled explicitely
   */
  private scheduleLogEvent(name: string, params: any = null, delay: number = 1000): ScheduledLogEvent {
    if (!this.isTrackingEnabled) {
      return;
    }

    const timeoutHandler = global.setTimeout(() => {
      this.logEvent(name, params);
    }, delay);

    return new ScheduledLogEvent(timeoutHandler);
  }

  /* SYNC */

  public trackSyncOpen() {
    this.logEvent('sync:open');
  }

  public trackSyncDetail() {
    this.logEvent('sync:detail');
  }

  public trackSyncClose() {
    this.logEvent('sync:close');
  }

  public trackSyncFinished() {
    this.logEvent('sync:finished');
  }

  /* DASHBOARD */
  public trackShowDashboard(actionEvent: ActionEvent) {
    this.logEvent('dashboard:show', { actionEvent });
  }

  public trackDashboardOpenPatient() {
    this.logEvent('dashboard:clicked-patient');
  }

  /* NOTIFICATIONS */

  public trackNotificationsOpen(actionEvent: ActionEvent) {
    this.logEvent('notifications:open', { actionEvent });
  }

  public trackNotificationsDelete(destination: string) {
    this.logEvent('notifications:action:delete', { destination });
  }

  public trackNotificationsNavigateTo(destination: string) {
    this.logEvent('notifications:action:navigate-to', { destination });
  }

  /* AUTH */
  public trackLogin() {
    this.logEvent('auth:login');
  }

  public trackLogout() {
    this.logEvent('auth:logout');
  }

  public trackLoginWrongPassword() {
    this.logEvent('auth:wrong-password');
  }

  /* PATIENT-LIST */
  public trackShowPatientList(actionEvent: ActionEvent) {
    this.logEvent('patient-list:show', { actionEvent });
  }

  public trackPatientListSearch() {
    this.logEvent('patient-list:search');
  }

  public trackPatientSortOpen() {
    this.logEvent('patient-list:sort-open');
  }

  public trackPatientSetSort(sort: object) {
    this.logEvent('patient-list:sort', sort);
  }

  public trackPatientFilterOpen() {
    this.logEvent('patient-list:filter-open');
  }

  public trackPatientSetFilter(filter: object) {
    this.logEvent('patient-list:filter-set', filter);
  }

  public trackPatientClearFilter() {
    this.logEvent('patient-list:filter-clear');
  }

  public trackAllPatientsOpen(actionEvent: ActionEvent) {
    this.logEvent('patient-list:allPatients', { actionEvent });
  }

  public trackMyPatientsOpen(actionEvent: ActionEvent) {
    this.logEvent('patient-list:myPatients', { actionEvent });
  }

  public trackChatOpen(actionEvent: ActionEvent) {
    this.logEvent('chat:open', { actionEvent });
  }

  /* EDIT-PATIENT-DIALOG */
  public trackShowEditPatientDialog(isNew: boolean) {
    this.logEvent('edit-patient-dialog:open', { isNew });
  }

  public trackSaveEditPatientDialog() {
    this.logEvent('edit-patient-dialog:save');
  }

  public trackCancelEditPatientDialog(hasUnsavedChanges: boolean) {
    this.logEvent('edit-patient-dialog:cancel', { hasUnsavedChanges });
  }

  /* PATIENT DASHBOARD */
  public trackPatientDashboardLoadingPage(event: ActionEvent, duration?: number): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:dashboard:load', { event, duration });
  }

  /* PATIENT MASTERDATA */
  public trackPatientMasterdataOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:masterdata:open', { actionEvent });
  }

  public trackPatientMasterdataActionEdit() {
    this.logEvent('patient:masterdata:action:edit');
  }

  /* PATIENT ATTACHMENTS */
  public trackPatientAttachmentsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:attachments:open', { actionEvent });
  }

  public trackPatientAttachmentsActionAdd(event: ActionEvent) {
    this.logEvent('patient:attachments:action:add', { event });
  }

  public trackPatientAttachmentsOpenAttachment() {
    this.logEvent('patient:attachments:open-attachment');
  }

  /* ATTACHMENT */
  public trackAttachmentShow(actionEvent: ActionEvent) {
    this.logEvent('patient:attachment:show', { actionEvent });
  }

  public trackAttachmentActionShare(event: ActionEvent) {
    this.logEvent('patient:attachment:action:share', { event });
  }

  public trackAttachmentActionEMail() {
    this.logEvent('patient:attachment:action:email');
  }

  public trackAttachmentActionDownload() {
    this.logEvent('patient:attachment:action:download');
  }

  public trackAttachmentActionFax(event: ActionEvent) {
    this.logEvent('patient:attachment:action:fax', { event });
  }

  public trackAttachmentChangeTherapy(actionEvent: ActionEvent) {
    this.logEvent('patient:attachment:action:change-therapy', { actionEvent });
  }

  /* PATIENT INSTITUTIONS */

  public trackPatientInstitutionsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:institutions:open', { actionEvent });
  }

  public trackPatientInstitutionDetail(institutionType: string, institutionId: string) {
    this.logEvent('patient:institution:detail', { institutionType, institutionId });
  }

  public trackPatientInstitutionEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:institution:action:edit', { actionEvent });
  }

  /* PATIENT CONTACTS */

  public trackPatientContactsOpen(): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:contacts:open');
  }

  public trackPatientContactDetail() {
    this.logEvent('patient:contact:detail');
  }

  public trackPatientContactEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:contact:action:edit', { actionEvent });
  }

  /* PATIENT DOCUMENTS */

  public trackPatientDocumentsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:documents:open', { actionEvent });
  }

  public trackPatientDocumentsSort(sort: object) {
    this.logEvent('patient:documents:sort', { sort });
  }

  public trackPatientDocumentsAdd(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:action:add', { actionEvent });
  }

  public trackPatientDocumentsShare(actionEvent: ActionEvent, shareEventTpe: ShareEventType) {
    this.logEvent('patient:documents:action:fax', { actionEvent, shareEventTpe });
  }

  public trackPatientDocumentsStatus(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:action:status', { actionEvent });
  }

  public trackPatientDocumentsMultiselect(enable: boolean) {
    this.logEvent('patient:documents:action:multiselect', { enable });
  }

  public trackPatientDocumentsSave(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:edit:save', { actionEvent });
  }

  public trackPatientDocumentsCompletedOnSave() {
    this.logEvent('patient:documents:edit:completedonsave');
  }

  public trackPatientDocumentsNotCompletedOnSave() {
    this.logEvent('patient:documents:edit:notcompletedonsave');
  }

  public trackPatientDocumentsSendEmailOnComplete(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:edit:email:sendoncomplete', { actionEvent });
  }

  public trackPatientDocumentsSendEmailAdressesSet() {
    this.logEvent('patient:documents:edit:email:adressesset');
  }

  public trackPatientDocumentsCheckingHashOfDocument(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:edit:hashofdocument', { actionEvent });
  }

  public trackPatientDocumentsSaveImagesAtAudit() {
    this.logEvent('patient:documents:edit:saveimagesataudit');
  }

  public trackPatientDocumentsSaveNoImagesAtAudit() {
    this.logEvent('patient:documents:edit:savenoimagesataudit');
  }

  public trackDocumentEditServiceSaveAudit(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:edit:documenteditservicesaveaudit', { actionEvent });
  }

  public trackDocumentEditServiceSaveAttachments() {
    this.logEvent('patient:documents:edit:documenteditservicesaveattachments');
  }

  public trackPatientDocumentOpen(actionEvent: ActionEvent) {
    this.logEvent('patient:document:open', { actionEvent });
  }

  public trackPatientDocumentStatus(
    actionEvent: ActionEvent,
    isCompleted?: boolean | undefined,
    isArchived?: boolean | undefined
  ) {
    this.logEvent('patient:document:action:status', { actionEvent, isCompleted, isArchived });
  }

  public trackPatientDocumentOpenReport() {
    this.logEvent('patient:document:open-report');
  }

  public trackPatientDocumentCopy(actionEvent: ActionEvent) {
    this.logEvent('patient:document:action:copy', { actionEvent });
  }

  public trackPatientDocumentEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:document:action:edit', { actionEvent });
  }

  public trackPatientDocumentShare(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('patient:document:action:share', { actionEvent, shareEventType });
  }

  public trackPatientDocumentConvertDocument(actionEvent: ActionEvent) {
    this.logEvent('patient:document:action:convert-document', { actionEvent });
  }

  public trackPatientDocumentReportShare(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('patient:document:report:action:share', { actionEvent, shareEventType });
  }

  public trackPatientDocumentReportPrint(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('patient:document:report:action:print', { actionEvent, shareEventType });
  }

  /* PATIENT ERP */

  public trackPatientErpOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:erp:open', { actionEvent });
  }

  /* PATIENT Notes */

  public trackPatientNotesOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:notes:open', { actionEvent });
  }
  public trackPatientNoteAdd(actionEvent: ActionEvent, isNew?: boolean, type?: NoteType) {
    this.logEvent('patient:note:add', { actionEvent, isNew, type });
  }

  public trackPatientNoteDetail(id: string) {
    this.logEvent('patient:note:detail', { id });
  }

  public trackPatientNoteEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:note:edit', { actionEvent });
  }

  public trackPatientNoteDelete(type?: NoteType) {
    this.logEvent('patient:note:edit', { type });
  }

  /* PATIENT Device */
  public trackPatientDeviceOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:device:open', { actionEvent });
  }

  public trackDeviceDetail(id: string) {
    this.logEvent('patient:device:detail', { id });
  }

  /* PATIENT ERP Task */
  public trackPatientErpTaskOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:erpTask:open', { actionEvent });
  }

  public trackErpTaskDetail(id: string) {
    this.logEvent('patient:erpTask:detail', { id });
  }

  /* Fax Protocol */
  public trackFaxProtocolOpen(actionEvent: ActionEvent) {
    this.logEvent('fax:protocol:open', { actionEvent });
  }

  public trackFaxProtocolSearch() {
    this.logEvent('fax:protocol:search');
  }

  public trackFaxProtocolFilterOpen() {
    this.logEvent('fax:protocol:filter-open');
  }

  public trackFaxProtocolFilter(filter: object) {
    this.logEvent('fax:protocol:filter-set', filter);
  }

  public trackFaxProtocolFilterClear() {
    this.logEvent('fax:protocol:filter-clear');
  }

  public trackFaxProtocolShowMoreCount(count: number) {
    this.logEvent('fax:protocol:show-more', { count });
  }

  public trackFaxProtocolDetail() {
    this.logEvent('fax:protocol:detail');
  }

  public trackFaxProtocolResend(actionEvent: ActionEvent) {
    this.logEvent('fax:protocol:action:resend', { actionEvent });
  }

  /* PRODUCTS */

  public trackProductsArticleOpen() {
    this.logEvent('products:article:open');
  }

  public trackProductsArticleSearch() {
    this.logEvent('products:article:search');
  }

  public trackProductsArticleDetail() {
    this.logEvent('products:article:detail');
  }

  /* INSTITIONS */

  public trackInstitutionSearchOpen(institutionType: InstitutionType, actionEvent?: ActionEvent) {
    this.logEvent('institution:search:open', { institutionType, actionEvent });
  }

  public trackInstitutionSearch(institutionType: InstitutionType) {
    this.logEvent('institution:action:search', { institutionType });
  }

  public trackInstitutionDetail(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:masterdata', { institutionType });
  }

  public trackInstitutionSubunits(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:subunits', { institutionType });
  }

  public trackInstitutionSubunitsAdd(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:subunits:action:add', { institutionType, actionEvent });
  }

  public trackInstitutionSubunitsEdit(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:subunits:action:edit', { institutionType, actionEvent });
  }

  public trackInstitutionContacts(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:contacts', { institutionType });
  }

  public trackInstitutionContactsAdd(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:contacts:action:add', { institutionType, actionEvent });
  }

  public trackInstitutionContactsEdit(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:contacts:action:edit', { institutionType, actionEvent });
  }

  public trackInstitutionNotes(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:notes', { institutionType });
  }

  public trackInstitutionNotesAdd(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:notes:action:add', { institutionType, actionEvent });
  }

  public trackInstitutionNotesEdit(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:notes:action:edit', { institutionType, actionEvent });
  }

  /* INSTITUTION DOCUMENTS */

  public trackInstitutionDocumentsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:documents:open', { actionEvent });
  }

  public trackInstitutionDocumentsAdd(actionEvent: ActionEvent) {
    this.logEvent('institution:documents:action:add', { actionEvent });
  }

  public trackInstitutionDocumentsShare(actionEvent: ActionEvent, shareEventTpe: ShareEventType) {
    this.logEvent('institution:documents:action:fax', { actionEvent, shareEventTpe });
  }

  public trackInstitutionDocumentOpen(actionEvent: ActionEvent) {
    this.logEvent('institution:document:open', { actionEvent });
  }

  public trackInstitutionDocumentStatus(
    actionEvent: ActionEvent,
    isCompleted?: boolean | undefined,
    isArchived?: boolean | undefined
  ) {
    this.logEvent('institution:document:action:status', { actionEvent, isCompleted, isArchived });
  }

  public trackInstitutionDocumentOpenReport() {
    this.logEvent('institution:document:open-report');
  }

  public trackInstitutionDocumentCopy(actionEvent: ActionEvent) {
    this.logEvent('institution:document:action:copy', { actionEvent });
  }

  public trackInstitutionDocumentEdit(actionEvent: ActionEvent) {
    this.logEvent('institution:document:action:edit', { actionEvent });
  }

  public trackInstitutionDocumentShare(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('institution:document:action:share', { actionEvent, shareEventType });
  }

  /* SETTINGS */

  public trackSettingsOpen() {
    this.logEvent('settings-general:open');
  }

  public trackSettingsContentOpen() {
    this.logEvent('settings-general:action:content-open');
  }

  public trackSettingsContentDelete(actionEvent: ActionEvent, storageName: string) {
    this.logEvent('settings-general:action:content-delete', { actionEvent, storageName });
  }

  public trackSettingsContentDeleteAll(actionEvent: ActionEvent) {
    this.logEvent('settings-general:action:content-delete-all', { actionEvent });
  }

  public trackSettingsIndexRepair(actionEvent: ActionEvent, storageName: string) {
    this.logEvent('settings-general:action:index-repair', { actionEvent, storageName });
  }

  /* HELP */

  public trackHelpOpen() {
    this.logEvent('help:open');
  }

  public trackHelpUpdateLink(url: string) {
    this.logEvent('help:action:update-link', { url });
  }

  /* CHAT */
  public trackChatShowChannels(numberOfChannels: number) {
    this.logEvent('chat:show-channels', { numberOfChannels });
  }

  /* CAREPROPOSAL */

  public trackCareproposalListOpen(actionEvent: ActionEvent) {
    this.logEvent('patient:careproposal:list:open', { actionEvent });
  }

  public trackCareproposalAdd(actionEvent: ActionEvent) {
    this.logEvent('patient:careproposal:action:add', { actionEvent });
  }

  public trackIntegratedCareProposalAdd(actionEvent: ActionEvent) {
    this.logEvent('patient:integratedCareproposal:action:add', { actionEvent });
  }

  public trackCareproposalDetail() {
    this.logEvent('patient:careproposal:detail');
  }

  public trackCareproposalSetState(actionEvent: ActionEvent, status: CareProposalStatus) {
    this.logEvent('patient:careproposal:action:set-state', { actionEvent, status });
  }
  public trackIntegratedCareSetState(actionEvent: ActionEvent, status: CareProposalStatus) {
    this.logEvent('patient:integratedCareproposal:action:set-state', { actionEvent, status });
  }
  public trackIntegratedCareSetArchived(actionEvent: ActionEvent) {
    this.logEvent('patient:integratedCareproposal:action:set-archived', { actionEvent });
  }

  public trackCareproposalEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:careproposal:action:edit', { actionEvent });
  }

  public trackIntegratedCareProposalEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:integratedCareproposal:action:edit', { actionEvent });
  }

  public trackCareproposalOrder(actionEvent: ActionEvent, orderType: string) {
    this.logEvent('patient:careproposal:action:order', { actionEvent, orderType });
  }

  public trackDeliveryInformationMismatch(actionEvent: ActionEvent, missMatchTyp: string) {
    this.logEvent('patient:order:deliveryInformationMismatch', { actionEvent, missMatchTyp });
  }

  public trackDeliveryInformationMismatchSave(actionEvent: ActionEvent) {
    this.logEvent('patient:order:deliveryInformationPatientSave', { actionEvent });
  }

  public trackChatOpenChannel(channel: ChatChannelDetail) {
    const params = {
      channelSid: channel.sid,
      uniqueName: channel.uniqueName,
      friendlyName: channel.friendlyName,
      memberCount: channel.members.length,
    };
    this.logEvent('chat:open-channel', params);
  }

  public trackChatSendMessage(channel: ChatChannelDetail, messageLength: number, emojis: any[]) {
    const params = {
      channelSid: channel.sid,
      uniqueName: channel.uniqueName,
      friendlyName: channel.friendlyName,
      memberCount: channel.members.length,
      messageLength,
      emojis,
    };
    this.logEvent('chat:send-message', params);
  }

  public trackPatientAppChatSendMessage(channel: ChatChannelDetail) {
    this._logPatientAppEvent('chat:sendMessage', {
      channelId: channel.sid,
      isActive: channel.isActive,
      isOpen: (channel.attributes as any)?.isOpen,
      clientType: 'agent',
    });
  }

  public trackPatientAppChatSendGreeting(channel: ChatChannelDetail) {
    this._logPatientAppEvent('chat:sendGreeting', {
      channelId: channel.sid,
      isActive: channel.isActive,
      isOpen: (channel.attributes as any)?.isOpen,
      clientType: 'agent',
    });
  }

  public trackPatientAppChatSendCloseMessage(channel: ChatChannelDetail) {
    this._logPatientAppEvent('chat:sendCloseMessage', {
      channelId: channel.sid,
      isActive: channel.isActive,
      isOpen: false,
      clientType: 'agent',
    });
  }

  public trackErpOrderDetailsShown(erpOrderId: string, isExpaned: boolean) {
    const params = {
      erpOrderId,
      isExpaned,
    };
    this.logEvent('erpOrder:expandDetail', params);
  }

  public chatAdvancedMessageActionButtonClicked(payload: MessagePayload) {
    this.logEvent('chat:advanced-message:clicked-action-button', payload);
  }

  /* RETOURE */

  public trackReturnDeliveryCreated(actionEvent: ActionEvent, id: string) {
    this.logEvent('returnDelivery:action:created', { actionEvent, id });
  }

  public trackReturnDeliveryEdited(actionEvent: ActionEvent, id: string) {
    this.logEvent('returnDelivery:action:edited', { actionEvent, id });
  }

  public trackIntegratedCareReturnDeliveryCreated(actionEvent: ActionEvent, id: string) {
    this.logEvent('integratedCareReturnDelivery:action:created', { actionEvent, id });
  }

  public trackIntegratedCareReturnDeliveryEdited(actionEvent: ActionEvent, id: string) {
    this.logEvent('integratedCareReturnDelivery:action:edited', { actionEvent, id });
  }

  public trackIntegratedCareReturnDeliveryDeleted(actionEvent: ActionEvent, id: string) {
    this.logEvent('integratedCareReturnDelivery:action:deleted', { actionEvent, id });
  }

  public trackIntegratedCareReturnDeliveryTransmitted(
    actionEvent: ActionEvent,
    id: string,
    hasArticles: boolean,
    hasComment: boolean
  ) {
    this.logEvent('integratedCareReturnDelivery:action:transmitted', { actionEvent, id, hasArticles, hasComment });
  }

  public trackIntegratedCareReturnDeliveryDetail() {
    this.logEvent('integratedCareReturnDelivery:detail');
  }

  public trackReturnDeliveryTransmitted(
    actionEvent: ActionEvent,
    id: string,
    hasArticles: boolean,
    hasComment: boolean
  ) {
    this.logEvent('returnDelivery:action:transmitted', { actionEvent, id, hasArticles, hasComment });
  }

  public trackReturnDeliveryDeleted(actionEvent: ActionEvent, id: string) {
    this.logEvent('returnDelivery:action:deleted', { actionEvent, id });
  }

  public trackReturnDeliverDetail() {
    this.logEvent('returnDelivery:detail');
  }

  /* PATIENT ACTIVITY */

  public trackPatientActivitiesOpen(actionEvent: ActionEvent, activityType: ActivityType) {
    this.scheduleLogEvent('patient:activities:open', { actionEvent, activityType });
  }

  public trackPatientActivityAdd(actionEvent: ActionEvent, activityType?: ActivityType) {
    this.logEvent('patient:activity:action:add', { actionEvent, activityType });
  }

  public trackPatientActivityEdit(actionEvent: ActionEvent, activityType?: ActivityType) {
    this.logEvent('patient:activity:action:edit', { actionEvent, activityType });
  }

  public trackPatientActivitiesShowCurrent() {
    this.logEvent('patient:activities:show:current');
  }

  public trackPatientActivitiesShowInPast() {
    this.logEvent('patient:activities:show:in-past');
  }

  public trackPatientActivityDetail(activityType: ActivityType) {
    this.logEvent('patient:activity:detail', { activityType });
  }

  /* Scanacs Zuzabe */
  public trackQueryCopayment(copaymentStatus: string, ikNumber: string) {
    this.logEvent('patient:activity:query-copayment', { copaymentStatus, ikNumber });
  }

  /* CALENDAR */
  public trackGrantAccessResult(result: boolean) {
    this.logEvent('calendar:grantAccessForOutlook', { accessGranted: result });
  }

  /* ErpTask */
  public trackErpTaskDueDateChanged() {
    this.logEvent('erpTask:dueDateChanged');
  }
  public trackErpTaskCreateDocument() {
    this.logEvent('erpTask:createDocument');
  }

  public trackErpTaskCreateAttachment() {
    this.logEvent('erpTask:createAttachment');
  }

  /* Barcode Scanner */
  public trackBarcodeScannerOpened() {
    this.logEvent('barcodescanner:open');
  }

  public trackBarcodeReceived() {
    this.logEvent('barcodescanner:codeReceived');
  }

  public trackBarcodeSetZoomLevel(zoomLevel: number) {
    this.logEvent('barcodescanner:setZoomLevel', { zoomLevel });
  }

  /* IntegratedCare */
  public trackIntegratedCareDetail() {
    this.logEvent('patient:integratedcare:detail');
  }

  public trackIntegratedCareOrder(actionEvent: ActionEvent) {
    this.logEvent('patient:integratedcare:order', { actionEvent });
  }

  public trackIntegratedCareStockCheck(
    integratedCareId: string,
    state: 'started' | 'failed' | 'success' | 'no-internet'
  ) {
    this.logEvent('patient:integratedCare:action:stock-check', { integratedCareId, state });
  }

  /* Reminder */
  public trackReminderCreated(actionEvent: ActionEvent, id: string) {
    this.logEvent('reminder:action:created', { actionEvent, id });
  }

  public trackReminderEdited(actionEvent: ActionEvent, id: string) {
    this.logEvent('reminder:action:edited', { actionEvent, id });
  }

  public trackDocumentPreventCopy(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:copy', { actionEvent });
  }

  public trackSyncCommandsActionShare(event: ActionEvent) {
    this.logEvent('sync:commands:action:share', { event });
  }

  /* Debug Infos */
  public trackDebugInfos(infos: unknown) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:debug:infos', infos);
  }

  public debugWebsocketSubscription(syncPipelineContextId: string, connectionState: boolean) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:debug:ws:subscribe', { connectionState, syncPipelineContextId });
  }

  public debugWebsocketUnsubscription(wasOfflineAtPause: boolean) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:debug:ws:unsubscribe', { wasOfflineAtPause });
  }

  public debugPlatformPause() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:debug:platform:pause');
  }

  public debugPlatformResume() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:debug:platform:resume');
  }

  /* App Start Pipeline */
  public debugPipelineStart() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:pipeline:start', { actionEvent: ActionEvent.Start });
  }

  public debugPipelineDone() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:pipeline:start', { actionEvent: ActionEvent.Done });
  }

  public debugPipelineElementStart(elementName: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:pipeline:element:${elementName}`, { actionEvent: ActionEvent.Start });
  }

  public debugPipelineElementDone(elementName: string, result: object) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:pipeline:element:${elementName}`, { actionEvent: ActionEvent.Done, ...result });
  }

  /* Sync Registry */
  public debugSyncPipelineStart(isOnline: boolean, isStartupSync: boolean, isLogin: boolean) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync-registry:start', { actionEvent: ActionEvent.Start, isOnline, isStartupSync, isLogin });
  }

  public debugSyncPipelineDone() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync-pipeline:start', { actionEvent: ActionEvent.Done });
  }

  public debugSyncPipelineElementStart(syncElementName: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync-pipeline:element:${syncElementName}`, { actionEvent: ActionEvent.Start });
  }

  public debugSyncPipelineElementDone(syncElementName: string, result: object) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync-pipeline:element:${syncElementName}`, { actionEvent: ActionEvent.Done, ...result });
  }

  public debugSyncPipelineElementNotExecuted(syncElementName: string, reason: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync-pipeline:element:${syncElementName}`, {
      actionEvent: ActionEvent.Cancel,
      syncElementNotExecutedReason: reason,
    });
  }

  public debugSyncPipelineAuthenticatorNoAuth() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync-pipeline:authenticator:noauth`);
  }

  /* SyncElement: Root History Setter */
  public debugSyncPipelineRootHistorySetter(navigateToParam: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync-pipeline:roothistorysetter`, { navigateTo: navigateToParam });
  }

  /* SyncElement: Shutdown Info Listener */
  public debugSyncPipelineShutdownTriggered() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync-pipeline:shutdowntriggered`);
  }

  /* SyncElement: Sync Indicator Opener */
  public debugSyncPipelineSyncIndicatorOpenerRestoreLastInstance(url: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync-pipeline:syncindicatoropener:restorelastinstance`, { url });
  }

  /* Sync: AttachmentSync */
  public debugSyncAttachmentSyncWsCreated(id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentsync:wscreated', { id });
  }

  public debugSyncAttachmentSyncWsRemoved(id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentsync:wsdeleted', { id });
  }

  public debugSyncAttachmentMyAttachmentsDownloadStart(id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentsync:download', { id, actionEvent: ActionEvent.Start });
  }

  public debugSyncAttachmentMyAttachmentsDownloadDone(id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentsync:download', { id, actionEvent: ActionEvent.Done });
  }

  public debugSyncAttachmentTotalItemsToSync(totalItemsToSync: number) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentsync:totalitems', { total: totalItemsToSync });
  }

  public debugSyncAttachmentQueueProcessorStart() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentqueue', { actionEvent: ActionEvent.Start });
  }

  public debugSyncAttachmentQueueProcessorError(queue: string, id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentqueue:error', { queue, id });
  }

  public debugSyncAttachmentQueueProcessorDone(queueInfo: {
    removeCount: number;
    saveCount: number;
    updateCount: number;
    localAttachmentsCount: number;
  }) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('app:sync:attachmentqueue', { actionEvent: ActionEvent.Done, ...queueInfo });
  }

  /* Sync Service */
  public debugSyncServiceSyncInternalStart(syncName: string, startTimestamp: Date, initialSyncStart: Date) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync:${syncName}:internalsync`, {
      actionEvent: ActionEvent.Start,
      startTimestamp,
      initialSyncStart,
    });
  }

  public debugSyncServiceSyncInternalDone(
    syncName: string,
    startTimestamp: Date,
    lastSyncTimestamp: Date,
    initialSyncStart: Date,
    syncFinished: boolean
  ) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync:${syncName}:internalsync`, {
      actionEvent: ActionEvent.Done,
      startTimestamp,
      lastSyncTimestamp,
      initialSyncStart,
      syncFinished,
    });
  }

  public debugSyncServiceCreate(syncName: string, id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync:${syncName}sync:create`, { id });
  }

  public debugSyncServiceUpdate(syncName: string, id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync:${syncName}sync:update`, { id });
  }

  public debugSyncServiceRemove(syncName: string, id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync:${syncName}sync:remove`, { id });
  }

  public debugSyncServicePatch(syncName: string, id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync:${syncName}sync:patch`, { id });
  }

  public debugSyncServiceGet(syncName: string, id: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`app:sync:${syncName}sync:get`, { id });
  }

  /* SyncElement: Index Repair */
  public debugIndexRepairStart() {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('index:repair:start', { actionEvent: ActionEvent.Start });
  }

  public debugIndexRepairTablesFound(tableNames: string[]) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent('index:repair:tablesneedrepair', {
      tableNames: tableNames.join(', '),
    });
  }

  public debugIndexRepairTableStart(tableName: string) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`index:repair:fixing:${tableName}`, { actionEvent: ActionEvent.Start });
  }

  public debugIndexRepairTableDone(tableName: string, elapsedTimeMs: number) {
    if (!this.isDebugUser()) {
      return;
    }
    this.logEvent(`index:repair:fixing:${tableName}`, { actionEvent: ActionEvent.Done, elapsedTime: elapsedTimeMs });
  }

  public trackIndexRepairStop() {
    this.logEvent('index:repair:stop', { actionEvent: ActionEvent.Done });
  }

  public trackIndexRepairCancel() {
    this.logEvent('index:repair:cancel', { actionEvent: ActionEvent.Cancel });
  }

  public trackIndexRepairDialogOpenTime(ms: number) {
    this.logEvent('index:repair:dialogOpenTime', { ms });
  }

  public trackDatabaseRecreationStart() {
    this.logEvent('database:recreation:start', { actionEvent: ActionEvent.Start });
  }

  public trackDatabaseRecreationEnd() {
    this.logEvent('database:recreation:done', { actionEvent: ActionEvent.Done });
  }

  /* DatabaseEncryptionKey */
  public trackMigrateKeyToCapacitorStart() {
    this.logEvent('encryption:migrateKeyToCapacitor:start', { actionEvent: ActionEvent.Start });
  }

  public trackMigrateKeyToCapacitorEnd() {
    this.logEvent('encryption:migrateKeyToCapacitor:done', { actionEvent: ActionEvent.Done });
  }

  /* CALENDAR */
  public trackCalendarOpen() {
    this.logEvent('calendar:open');
  }

  public trackCalendarPatientDataResourceChanged() {
    this.logEvent('calendar:patientDataResourceChanged');
  }
}
