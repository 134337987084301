import { Injectable } from '@angular/core';
import { AlertController, AlertInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ISelectionItem } from 'src/app/common/contracts/components/selection-item';
import { Deferred } from 'src/app/common/deferred/deferred';

export interface ISelectionOptions {
  compareFn: (id: any, value: any) => boolean;
  changeAllowed: () => Promise<boolean>;
}
export interface ISelectionReturnObject {
  canceled: boolean;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  private _ready: Deferred<ISelectionReturnObject>;
  private _alert: HTMLIonAlertElement;

  constructor(private _alertController: AlertController, private _translate: TranslateService) {}
  public async openSelection(
    currentSelected: any,
    values: ISelectionItem[],
    title: string,
    options?: ISelectionOptions
  ) {
    this._ready = new Deferred();

    const inputs = this.createInputs(currentSelected, values, options);
    // create the alert
    this._alert = await this._alertController.create({
      header: title,
      inputs: inputs,
      cssClass: 'overridePrimaryToVariant',
      buttons: this.createButtons(),
    });
    await this._alert.present();
    await this._alert.onDidDismiss();

    return this._ready.promise;
  }

  public checkIfchecked(currentSelected: any, value: any, compareFn?: (id: any, value: any) => boolean): boolean {
    if (compareFn && typeof compareFn === 'function') {
      return compareFn(currentSelected, value);
    }
    return currentSelected === value;
  }

  private createInputs(currentSelected: any, values: ISelectionItem[], options?: ISelectionOptions): any[] {
    const inputs = [];
    if (!values || !values.length) {
      return inputs;
    }
    // gernerating the radio options
    values.forEach(value => {
      inputs.push({
        type: 'radio',
        label: value.value,
        value: value.id,
        disabled: value.disabled,
        checked: this.checkIfchecked(
          currentSelected,
          value.id,
          options && options.compareFn ? options.compareFn : undefined
        ),
        handler: (input: AlertInput) =>
          this.saveAndClose(input, options && options.changeAllowed ? options.changeAllowed : undefined),
      });
    });
    return inputs;
  }

  public saveAndClose(input: AlertInput, changeAllowed: () => Promise<boolean>) {
    const emitChange = () => {
      this._ready.resolve({
        canceled: false,
        value: input.value,
      });
      this._alertController.dismiss();
    };
    if (changeAllowed && typeof changeAllowed === 'function') {
      changeAllowed().then(allowed => {
        if (allowed) {
          emitChange();
        }
      });
    } else {
      emitChange();
    }
    this._alert.dismiss();
  }

  public createButtons() {
    return [
      {
        text: this._translate.instant('CANCEL_BUTTON'),
        handler: () => {
          this._ready.resolve({
            canceled: true,
            value: undefined,
          });
        },
      },
    ];
  }
}
